import { numAutoValidator } from '@/core'

export const ruleFormAddTimeslot = {
  plate_truck: [
    {
      required: true,
      message: 'Заполните номер авто',
      trigger: 'blur',
    },
    {
      required: true,
      validator: (rule, value, callback) => {
        numAutoValidator(value) ? callback(new Error()) : callback()
      },
      message:
        'Формат номера должен быть "м213тт99" или "1212ттт" или "e2233кк"',
      trigger: 'blur',
    },
  ],
  phone: [
    {
      required: true,
      message: 'Заполните телефон водителя',
      trigger: 'blur',
    },
  ],
  type: [
    {
      required: true,
      message: 'Выберите тип авто',
      trigger: 'change',
    },
  ],
  culture_id: [
    {
      required: true,
      message: 'Выберите культуру',
      trigger: 'change',
    },
  ],
  date: [
    {
      required: true,
      message: 'Пожалуйста, выберите дату ',
      trigger: 'submit',
    },
  ],
  time: [
    {
      type: 'string',
      required: true,
      message: 'Пожалуйста, выберите время ',
      trigger: 'change',
    },
  ],
  detach: [
    {
      required: true,
      message: 'Выберите состояние',
      trigger: 'change',
    },
  ],
}
